body {
  font-family: sans-serif;
}
.linked-code-decoration-inline-0 {
    background: #005bbb !important;
}

.linked-code-decoration-inline-1 {
    background: #61d6eb !important;
}

.linked-code-decoration-inline-2 {
    background: #a1caf4 !important;
}

.linked-code-decoration-inline-3 {
    background: #c7c0f4 !important;
}

.linked-code-decoration-inline-4 {
    background: #eab3f4 !important;
}

.linked-code-decoration-inline-5 {
    background: #f5b3d9 !important;
}

.linked-code-decoration-inline-6 {
    background: #ffd500 !important;
}

.linked-code-decoration-inline-7 {
    background: #f6b8a0 !important;
}

.linked-code-decoration-inline-8 {
    background: #edc058 !important;
}

.linked-code-decoration-inline-9 {
    background: #c4ce58 !important;
}

.linked-code-decoration-inline-10 {
    background: #7ddc58 !important;
}

.linked-code-decoration-inline-11 {
    background: #5bdca8 !important;
}

.tab-decoration-0 {
    border-top-color: #005bbb !important;
}

.tab-decoration-1 {
    border-top-color: #61d6eb !important;
}

.tab-decoration-2 {
    border-top-color: #a1caf4 !important;
}

.tab-decoration-3 {
    border-top-color: #c7c0f4 !important;
}

.tab-decoration-4 {
    border-top-color: #eab3f4 !important;
}

.tab-decoration-5 {
    border-top-color: #f5b3d9 !important;
}

.tab-decoration-6 {
    border-top-color: #ffd500 !important;
}

.tab-decoration-7 {
    border-top-color: #f6b8a0 !important;
}

.tab-decoration-8 {
    border-top-color: #edc058 !important;
}

.tab-decoration-9 {
    border-top-color: #c4ce58 !important;
}

.tab-decoration-10 {
    border-top-color: #7ddc58 !important;
}

.tab-decoration-11 {
    border-top-color: #5bdca8 !important;
}

$primary: #367d76;
$dark: #224f4a;
$secondary: #4b799b;
@import "../../node_modules/bootstrap/scss/bootstrap";
